import styled from 'styled-components';


export const Container = styled.div`
  max-width: 80%;
  margin: 10px auto;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 0.1px 2px rgba(0, 0, 0, 0.1);
`;

export const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  color: white;
  border-bottom: 1px solid #f0f0f0;
  background-color: #204C78;
  padding: 22px;
`;


export const LabelText = styled.h2`
  text-align: center;
  margin-bottom: 10px;
  color: #204C78;
  border-radius: 2px;
  background-color: white;
`;