import { useMemo } from 'react';
import {Container} from'../styles/container';
import { Box, Button } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { mkConfig, generateCsv, download } from 'export-to-csv';

import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
} from 'material-react-table';


type UserPrincipal = {
    id: number;
    userPrincipalName: string;
    hasValidPhoneLicense: boolean | string;
    licenseList: string;
    icon?: JSX.Element;
}


export const LicenseResultViewMRT = (props: { data: UserPrincipal[] }) => {
  const columns = useMemo<MRT_ColumnDef<UserPrincipal, any>[]>(
    () => [
      {
        accessorKey: 'userPrincipalName',
        header: 'User Principal Name',
        size: 250,
        muiFilterTextFieldProps: {
          sx: {
            color: '#abcded',
          }},
      },
      {
        accessorKey: 'licenseList',
        header: 'Assigned Licenses',
        size: 250,
      },
      {
        accessorKey: 'icon',
        header: 'Has Valid Phone License',
        size: 150,
        filterFn: (row, columnId, value) => {
          return row.original.hasValidPhoneLicense === (value === 'true');
        },
        filterVariant: 'select',
        filterSelectOptions: ['','true' , 'false'],
      }
    ],
    [],
  );


  const createNewFilename = () => {
    const date = new Date();
    return `NUSO_MSTeams_License_Check_${date.toISOString()}`;
  }

  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true
  });


  const handleExportRows = (rows: MRT_Row<any>[]) => {
    const rowData = rows.map((row) => row.original);
    rowData.forEach( row=>delete row.icon);
    rowData.forEach( row=>row.hasValidPhoneLicense = row.hasValidPhoneLicense ? 'Yes' : 'No');
    const dynamicCsvConfig = {
      ...csvConfig,
      filename: createNewFilename(),
     };
    const csv = generateCsv(dynamicCsvConfig)(rowData);
    download(dynamicCsvConfig)(csv);
  };

  const handleExportData = (users: UserPrincipal[]) => {
    console.debug('Exporting all data', users);

    // REFACTOR
    const updatedUsers = users.map(user  => {
      const { icon, ...rest } = user;
      return {...rest, hasValidPhoneLicense: rest.hasValidPhoneLicense ? 'Yes' : 'No'}
    });
    const dynamicCsvConfig = {
      ...csvConfig,
      filename: createNewFilename(),
    };
   const csv = generateCsv(dynamicCsvConfig)(updatedUsers);
   download(dynamicCsvConfig)(csv);
  };

  const table = useMaterialReactTable({
    columns,
    data: props.data,
    enableStickyHeader: true,
    muiPaginationProps: {
      rowsPerPageOptions: [10, 50, 100,2500],
      sx: {
        fontSize: '0.5rem',
        color: '#204C78',
      },
    },
    initialState: {
      columnOrder: ['userPrincipalName', 'licenseList', 'icon'],
      pagination: { pageIndex: 0, pageSize: 50 },
    },
    muiFilterTextFieldProps:{
      sx: {
        color: '#f0f0f0',
        fontWeight: 'bold',
        fontSize: '1.0rem',
      }
    },
    muiTableHeadCellProps:{
        sx: {
          backgroundColor: '#f1f1f1',
          color: '#000',
          fontWeight: 'bold',
          fontSize: '1.0rem',
        },
      },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          /// export all data that is currently in the table (ignoring pagination, sorting, filtering, etc.)
          onClick={() =>
            handleExportData(props.data)
          }
          startIcon={<SaveAltIcon />}
          sx={{fontSize: '0.8rem'}}
        >
          Export All
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          /// export all rows as seen on the screen (respecting pagination, sorting, filtering, etc.)
          onClick={() => handleExportRows(table.getRowModel().rows)}
          startIcon={<ArrowDownwardIcon/>}
          sx={{fontSize: '0.8rem'}}
        >
          Export View
        </Button>
      </Box>

    ),
  });

  return (
    <div>
      <Container><MaterialReactTable table={table} /></Container>
    </div>
    );
};


