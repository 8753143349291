import {Alert,AlertTitle} from '@mui/material';
import { Table, TableRow, TableCell } from '@mui/material';

const MINIMUM_PHONE_LICENSES = 2;

interface LicenseAllocationSummary {
  totalEnabled?: number;
  totalRemaining: number;
}


const getLicenseCheckDeficitWarningMessage = (licenses: LicenseAllocationSummary) => {

   return (
    <Alert severity="warning" variant="filled" sx={{fontSize: '0.9rem'}}>
      <AlertTitle sx={{fontSize: '0.9rem'}}>Note - Action Required</AlertTitle>

      Your organization currently has <strong> {licenses.totalEnabled}</strong> active phone licenses,
      but <strong>{licenses.totalRemaining}</strong> are available for provisioning NUSO Bridge services.
      This means that you do not have enough phone licenses to set up Direct Routing with NUSO Bridge For Teams.

      <br/><br/>

      To proceed, please either purchase or free up {MINIMUM_PHONE_LICENSES - licenses.totalRemaining } Teams Phone Capable licenses.
      Once the NUSO Bridge service is successfully set up, you can reassign both licenses.
    </Alert>
  );
}


const getLicenseCheckSuccessMessage = (licenses: LicenseAllocationSummary) => {
  return (
    <Alert severity="success" variant="filled" sx={{fontSize: '0.9rem', textAlign: 'left', padding:'11px'}}>
      <AlertTitle sx={{fontSize: '0.9rem'}}>Good News - License Check Passed</AlertTitle>
      Your organization currently has <strong>{licenses.totalEnabled} </strong> active phone licenses,
      with <strong>{licenses.totalRemaining} </strong> available for provisioning NUSO Bridge services.
      This means you can go ahead and set up Direct Routing with NUSO Bridge For Teams.

      <br/><br/>

      Once the NUSO Bridge service is successfully set up, the 2 licenses used for setting up Direct Routing can be reassigned.
    </Alert>
  );
}


export const SummaryInformationTextComponent = (props: LicenseAllocationSummary) => {
  return (

      <Table >
        <TableRow>
          <TableCell >
              {
              props.totalRemaining < MINIMUM_PHONE_LICENSES ? getLicenseCheckDeficitWarningMessage(props)
                : getLicenseCheckSuccessMessage(props)
              }
          </TableCell>
        </TableRow>
      </Table>
  )
}