import { Table, TextField, TableRow, TableCell } from '@mui/material';
import { SUMMARY_LABELS } from '../labels/labels';
import "../styles/style.css";

interface SummaryTilesComponentPros {
  totalEnabled: number;
  totalRemaining: number;
  showTitle?: boolean;
}

export const SummaryTilesComponent = (props: SummaryTilesComponentPros) => {

  const MINIMUM_PHONE_LICENSES = 2;

  const getTotalEnabledLicenses = () => {
    return props.totalEnabled;
  }

  const getTotalRemainingLicenses = () => {
    return props.totalRemaining;
  }

  const getAdditionalLicensesRequired = () => {
     return props.totalRemaining < MINIMUM_PHONE_LICENSES ?
       (MINIMUM_PHONE_LICENSES - props.totalRemaining)
       : 0;
  }

  const inputPropsStyle =  {style: {
    textAlign: 'center' as 'center',
    fontSize: '1.1rem',
    fontWeight: 'bold'
  }};

  return (
      <Table className="summary-table">
        <TableRow>
          <TableCell className='summary-table-cell'>
            <TextField
              label={SUMMARY_LABELS.SUBSCRIPTION_PHONE_LICENSES}
              fullWidth
              value={`${getTotalEnabledLicenses()}`}
              size="medium"
              className='summary-table-cell-text'
              InputProps={{
                readOnly: true,
                inputProps: inputPropsStyle,
              }}/>
          </TableCell>
          <TableCell className='summary-table-cell'>
            <TextField
              label={SUMMARY_LABELS.UNALLOCATED_PHONE_LICENSES}
              fullWidth
              value={`${getTotalRemainingLicenses()}`}
              size="medium"
              className='summary-table-cell-text'
              InputProps={{
                readOnly: true,
                inputProps:  inputPropsStyle,
              }}/>
          </TableCell>
          <TableCell className='summary-table-cell'>
            <TextField
              label={SUMMARY_LABELS.REQUIRED_PHONE_LICENSES}
              fullWidth
              value={getAdditionalLicensesRequired()}
              size="medium"
              className='summary-table-cell-text'
              InputProps={{
                readOnly: true,
                inputProps:  inputPropsStyle,
              }}/>
          </TableCell>
        </TableRow  >
      </Table>
  )

}