
import axios from "axios"

export class Users {


  public static async getUsersFromAPI(tokenId: string) {
    console.debug(this.getUsersFromAPI.name, tokenId);
    try {
      const response = await axios.get(`https://nuso-prod-us-east-2-licensing-svc.mybridges.cloud/licenses/${tokenId}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      console.debug("Axios Response", response.data);
      return response.data;
    }
    catch (error: any) {
      if (error.response) {
        console.error("Axios Response - Error", error.response.data);
      }
      else {
        console.error("Error", error.message);
      }
      return [];
    }
  }


}