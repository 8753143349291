import { DataGridRow } from "../interfaces/DataGridRow";
import { UserLicenseData } from "../interfaces/UserLicenseData";

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


export interface LicenseSummaryEntry {
  remainingLicenses: number;
  enabledLicenses: number;
  consumedLicenses: number;
}


export interface LicenseSummaryData {
  licenseSummary: LicenseSummaryEntry[];
}


export interface LicenseSummaryResult {
  totalRemaining: number;
  totalEnabled: number;
  totalConsumed: number;
}


export class LicenseHelper {

  static summarizeLicenses(data: LicenseSummaryData) {

    let totalRemaining = 0;
    let totalEnabled = 0;
    let totalConsumed = 0;

    for (const entry of data.licenseSummary) {
      totalRemaining += entry.remainingLicenses;
      totalEnabled += entry.enabledLicenses;
      totalConsumed += entry.consumedLicenses;
    }

    return {
      totalRemaining,
      totalEnabled,
      totalConsumed
    };
  }


  static parseLicenseData(jsonData: UserLicenseData[]): DataGridRow[] | any {

    if (!Array.isArray(jsonData)) {
      return [];
    }
    try {

      return jsonData.map((user, index) => {
        const hasValidPhoneLicense = user.assignedLicenses.some(
          (license) => license.isValidPhoneLicense
        );
        let licenseList = "";
        for (const licenses of user.assignedLicenses) {
          if (licenseList.length > 0) {
            licenseList += ", " + licenses.skuPartNumber;
          }
          else {
            licenseList = licenses.skuPartNumber;
          }
        }

        return {
          id: index,
          userPrincipalName: user.userPrincipalName,
          hasValidPhoneLicense: hasValidPhoneLicense,
          licenseList: licenseList.length > 0 ? licenseList : "** User Has No Licenses **",
          icon: hasValidPhoneLicense ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
        };
      }).filter(Boolean) as DataGridRow[]; // Filter out any undefined values
    } catch (error: any) {
      console.error("Error parsing jsonData", error.message);
      return [];
    }

  }
}