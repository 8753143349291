import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { SummaryTilesComponent } from './summaryTiles';
import { SummaryInformationTextComponent } from './summaryInformationText';
import "../styles/style.css";

import {Container, LabelText} from '../styles/container';
import { Table, TableCell, TableRow } from '@mui/material';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


interface SummaryTilesComponentPros {
  totalEnabled: number;
  totalRemaining: number;
  showTitle?: boolean;
}

enum TabIndex {
  DirectRouting = 0,
  OperatorConnect = 1,
}

function LicensingTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function tabProperties(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function LicenseTypeView(props: SummaryTilesComponentPros) {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box className="header-box">

        <Table>
          <TableRow>
            <TableCell style={{width:` 30%`}}>
              <LabelText>What licensing will I need for:</LabelText>
            </TableCell>
            <TableCell style={{width:` 70%`}}>
              <Tabs  value={value} onChange={handleChange} aria-label="licensing tabs" variant="fullWidth"
                TabIndicatorProps={{
                  style: { display: 'none' }
                }} >
                <Tab label="Sip Connect | Platform Connect" {...tabProperties(TabIndex.DirectRouting)}
                  className={`tab ${value === TabIndex.DirectRouting ? 'tab-active' : 'tab-inactive'}`}
                />
                <Tab label="Operator Connect" {...tabProperties(TabIndex.OperatorConnect)}
                  className={`tab ${value === TabIndex.OperatorConnect ? 'tab-active' : 'tab-inactive'}`}
                />
              </Tabs>
            </TableCell>
          </TableRow>
        </Table>

      </Box>

      <LicensingTabPanel value={value} index={TabIndex.DirectRouting}>
        <SummaryTilesComponent totalEnabled={props.totalEnabled} totalRemaining={props.totalRemaining} />
        <SummaryInformationTextComponent totalEnabled={props.totalEnabled} totalRemaining={props.totalRemaining} />
      </LicensingTabPanel>

      <LicensingTabPanel value={value} index={TabIndex.OperatorConnect}>
        <div/>
      </LicensingTabPanel>

    </Container>
  );
}
